
import { ObjPairBoolean } from '@/types/general'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Button',
  emits: ['button-click'],
  props: {
    type: {
      type: String, // button, submit
      required: false,
      default: 'button',
    },
    label: {
      type: String,
      required: false,
      default: 'button',
    },
    to: {
      type: [Object, Boolean],
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
      default: 'primary', // 'primary' | 'grey' | 'salmon'
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: 'medium', // 'small' | 'medium'
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasShadow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    rootClasses(): ObjPairBoolean {
      return {
        IsDisabled: this.disabled,
        IsLoading: this.isLoading,
        Link: this.color === 'link',
        ColorPrimary: this.color === 'primary' && !this.outlined,
        PrimaryOutlined: this.color === 'primary' && this.outlined,
        ColorGrey: this.color === 'grey' && !this.outlined,
        GreyOutlined: this.color === 'grey' && this.outlined,
        ColorSalmon: this.color === 'salmon' && !this.outlined,
        SalmonOutlined: this.color === 'salmon' && this.outlined,
        SizeSmall: this.size === 'small',
        SizeMedium: this.size === 'medium',
        IsFullWidth: this.fullWidth,
        HasShadow: this.hasShadow,
      }
    },
  },
  methods: {
    onClick() {
      if (this.disabled) {
        return
      }

      if (this.isLoading) {
        return
      }

      if (this.to) {
        return
      }

      this.$emit('button-click')
    },
  },
})
