
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Page',
  computed: {
    showVersion2Warning() {
      return this.$route.fullPath.includes('/v2/')
    },
  },
})
