

import { computed, ComputedRef, defineComponent } from 'vue'

export default defineComponent({
  setup(props, context): { hasTitle: ComputedRef<boolean> } {
    return {
      hasTitle: computed(() => !!context.slots.title),
    }
  },
})

