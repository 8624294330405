
import {
  computed, defineComponent,
} from 'vue'

export default defineComponent({
  name: 'InputField',
  emits: [
    'update:data',
    'field-input',
    'field-blur',
  ],
  props: {
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    name: String,
    required: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    data: {
      type: [String, Number],
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    unit: {
      type: String,
      required: false,
      default: '',
    },
    maxlength: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    errorText: {
      type: String,
      required: false,
      default: '',
    },
    hideSuggestions: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const onChange = (event: Event) => {
      const originalValue = props.data ?? ''
      const stringValue = (event.target as HTMLInputElement).value
      const value = typeof originalValue === 'string' ? stringValue : Number(stringValue)
      if (!Number.isNaN(value)) {
        emit('update:data', value)
      }

      emit('field-input')
    }

    const onBlur = () => {
      emit('field-blur', props.data)
    }

    return {
      value: computed(() => props.data?.toString() || ''),
      onChange,
      onBlur,
    }
  },
})

