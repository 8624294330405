
import { defineComponent, PropType } from 'vue'

export interface Checkbox {
  key: string
  label: string
}

export default defineComponent({
  props: {
    name: String,
    title: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    data: {
      type: Object as PropType<string[]>,
    },
    checkboxes: {
      type: Object as PropType<Checkbox[]>,
    },
  },
  setup(props, { emit }) {
    return {
      onCheck: (key: string) => {
        if (props.data?.includes(key)) {
          emit('update:data', props.data.filter((keys) => keys !== key))
        }
        else {
          emit('update:data', props.data?.concat([key]))
        }
      },
    }
  },
})

