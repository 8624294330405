
import { defineComponent } from 'vue'

export default defineComponent({
  emits: ['update:data', 'change'],
  props: {
    name: String,
    required: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    data: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    options: Array,
  },
  methods: {
    onChange(event: Event) {
      this.$emit('update:data', (event.target as HTMLInputElement).value)
      this.$emit('change')
    },
  },
})

