

import { defineComponent } from 'vue'

export default defineComponent({
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true,
    },
    data: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
})
